import { navigate } from 'gatsby';
import { useLayoutEffect } from 'react';

import { useLocation } from '@src/context/location';
import { useLeadOrigin } from '@src/hooks/useLeadOrigin';

const LandingPage = () => {
  const { origin = 'bodil' } = useLeadOrigin();

  const location = useLocation();

  useLayoutEffect(() => {
    const urlQuery = location?.href.split('?')[1];

    const urlQueryPart = urlQuery ? `?${urlQuery}` : '';

    navigate(`/${origin}/address${urlQueryPart}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LandingPage;
